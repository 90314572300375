import '../home/css/style'; // import flaticons

import styled, { ThemeProvider } from 'styled-components';

import Banner from '../home/containers/Banner';
import { Modal } from '@redq/reuse-modal';
import Navbar from './navbar/navbar';
import React from 'react';
import ResetCss from '../components/reset-css';
import ScrollToTop from 'react-scroll-up';
import ScrollUpButton from '../components/scroll-up-button/scroll-up-button';
import ServiceSection from './Service';
import Sticky from 'react-stickynode';
import SuperPropsFooter from './Footer';
import { theme } from '../theme';

const Wrapper = styled.div`
	width: 1385px;
	padding: 30px 15px;
	margin: 0 auto;
	@media (max-width: 1420px) {
		width: 1170px;
	}
	@media (max-width: 1200px) {
		width: 100%;
		padding: 30px 20px;
	}
`;

type LayoutProps = {
	children: React.ReactNode;
	isIndex?: boolean;
};

const Layout: React.FunctionComponent<LayoutProps> = ({ children, isIndex }) => {
	return (
		<ThemeProvider theme={theme}>
			<>
				<Modal />
				<ResetCss />
				<Sticky top={0} innerZ={9999} activeClass='nav-sticky'>
					<Navbar />
				</Sticky>

				{isIndex && (
					<>
						<Banner />
						<ServiceSection />
					</>

				)}

				<Wrapper>
					{children}
				</Wrapper>


				<SuperPropsFooter />

				<ScrollToTop
					showUnder={300}
					duration={700}
					easing='easeInOutCubic'
					style={{ bottom: 30, right: 20 }}
				>
					<ScrollUpButton />
				</ScrollToTop>
			</>
		</ThemeProvider>
	);
};

export default Layout;
