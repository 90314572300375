import HeaderWrapper, {
	HeaderTop,
	Logo,
	MenuWrapper,
	MobileMenu,
	NavSearchButton,
	NavSearchFromWrapper,
	NavSearchWrapper,
	SearchCloseButton,
	SocialProfileWrapper,
	TopBarWrapper,
} from './navbar.style';
import { IoIosClose, IoIosSearch } from 'react-icons/io';
import { IoLogoGithub, IoLogoInstagram, IoLogoLinkedin, IoLogoTwitter } from 'react-icons/io';
import React, { useState } from 'react';

import Drawer from './drawer';
import { DrawerProvider } from '../../components/drawer/drawer-context';
import { Link } from 'gatsby';
import Menu from './menu';
import SearchContainer from '../search/search';
import SocialProfile from '../../components/social-profile/social-profile';
import logoImage from '../../images/logo.svg';

type NavbarProps = {
	className?: string;
};

const MenuItems = [
	{
		label: 'Home',
		url: '/',
	},
	{
		label: 'Contact',
		url: '/contact',
	}
];

const SocialLinks = [
	// TODO add company socials
	{
		icon: <IoLogoGithub />,
		url: 'https://www.github.com/ceddybi/',
		tooltip: '',
	},
	{
		icon: <IoLogoInstagram />,
		url: 'https://www.instagram.com/ceddymuhoza/',
		tooltip: '',
	},
	{
		icon: <IoLogoTwitter />,
		url: 'https://twitter.com/ceddymuhoza',
		tooltip: '',
	},
	{
		icon: <IoLogoLinkedin />,
		url: 'https://www.linkedin.com/in/ceddymuhoza/',
		tooltip: '',
	},
];

const Navbar: React.FunctionComponent<NavbarProps> = ({
	className,
	...props
}) => {
	const [state, setState] = useState({
		toggle: false,
		search: '',
	});

	const toggleHandle = () => {
		setState({
			...state,
			toggle: !state.toggle,
		});
	};

	// Add all classs to an array
	const addAllClasses = ['header'];

	// className prop checking
	if (className) {
		addAllClasses.push(className);
	}

	return (
		<>
			<HeaderWrapper className={addAllClasses.join(' ')} {...props}>
				<HeaderTop>
					<TopBarWrapper>
						<MobileMenu>
							<DrawerProvider>
								<Drawer items={MenuItems} logo={logoImage} />
							</DrawerProvider>
						</MobileMenu>

						<Logo>
							<Link to='/'>
								<img src={logoImage} alt='logo' />
							</Link>
						</Logo>

						<MenuWrapper>
							<Menu items={MenuItems} />
						</MenuWrapper>

						<NavSearchButton
							type='button'
							aria-label='search'
							onClick={toggleHandle}
						>
							<IoIosSearch size='23px' />
						</NavSearchButton>

						<SocialProfileWrapper>
							<SocialProfile items={SocialLinks} />
						</SocialProfileWrapper>
					</TopBarWrapper>
					<NavSearchWrapper className={state.toggle === true ? 'expand' : ''}>
						<NavSearchFromWrapper>
							<SearchContainer />
							<SearchCloseButton
								type='submit'
								aria-label='close'
								onClick={toggleHandle}
							>
								<IoIosClose />
							</SearchCloseButton>
						</NavSearchFromWrapper>
					</NavSearchWrapper>
				</HeaderTop>
			</HeaderWrapper>
		</>

	);
};

export default Navbar;
