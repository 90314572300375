import styled, { keyframes } from 'styled-components';

import map from '../../home/image/map.png';
import { themeGet } from '@styled-system/theme-get';

const slideShow = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const FooterWrapper = styled.footer`
  width: 100%;
  padding: 70px 0 40px;
  background-color: ${themeGet('colors.heading', '#171717')};
  background-image: url(${map});
  background-repeat: no-repeat;
  background-position: top center;

  .col-one {
    .logo {
      width: 110px;
      margin-bottom: 20px;
    }
  }

  .widget_title {
    color: ${themeGet('colors.label', '#C6C6C6')};
    font-size: 14px;
    margin-bottom: 16px;
  }

  .text {
    color: ${themeGet('colors.label', '#C6C6C6')};
    font-size: 14px;
    margin-bottom: 9px;
  }

  .copyright {
    width: 100%;
    margin-top: 81px;
    padding-top: 30px;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #212121;
    @media only screen and (max-width: 480px) {
      justify-content: center;
      margin-top: 10px;
      padding-top: 25px;
      flex-direction: column;
    }

    p {
      color: #464646;
      margin: 0;

      svg {
        margin-left: 10px;
      }
    }
  }
`;

export const CurvIcon = styled.div`
  background-color: ${themeGet('colors.lightBg', '#fbfafe')};

  svg {
    width: 100%;
    margin-bottom: -7px;
    fill: ${themeGet('colors.heading', '#171717')};
  }
`;

export const List = styled.ul`
  width: 100%;
`;

export const ListItem = styled.li`
  a {
    color: ${themeGet('colors.lightText', '#7E7E7E')};
    font-size: 14px;
    line-height: 35px;
    transition: all 0.3s ease;

    &:hover,
    &:focus {
      outline: 0;
      text-decoration: none;
      color: ${themeGet('colors.inactiveIcon', '#EBEBEB')};
      padding-left: 5px;
    }
  }
`;

export const SocialList = styled.ul`
  display: flex;
  align-items: center;
  margin-top: 30px;

  li {
    margin-right: 30px;
    @media only screen and (max-width: 991px) {
      margin-right: 20px;
    }
    &:last-child {
      margin-right: 0;
    }

    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      color: ${themeGet('colors.lightText', '#7E7E7E')};
      position: relative;
      i {
        position: relative;
        z-index: 1;

        svg {
          width: 15px;
          height: auto;
        }
      }

      &:hover {
        color: ${themeGet('colors.primary', '#FDEF00')};
      }
    }
  }
`;

export const ClientWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  position: relative;
  @media only screen and (max-width: 624px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media only screen and (max-width: 480px) {
    padding-left: 0px;
    padding-right: 0px;
  }
  .client__text {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &:before {
      position: absolute;
      content: '';
      height: 1px;
      width: 100%;
      background-color: ${themeGet('colors.borderColorTwo', '#696871')};
      top: 50%;
      left: 0;
    }
    span {
      position: relative;
      font-size: 16px;
      margin-bottom: 0;
      z-index: 2;
      padding: 0 30px;
      line-height: 1.8;
      text-align: center;
      color: ${themeGet('colors.light', '#FAF7FA')};
      @media only screen and (max-width: 768px) {
        padding: 0 15px;
      }
    }
  }
`;

export const ImageSlider = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding: 57px 0px 25px;
  width: calc(100% - 60px);
  @media only screen and (max-width: 991px) {
    padding-top: 65px;
  }
  @media only screen and (max-width: 624px) {
    padding-top: 45px;
  }
  &::before,
  &::after {
    content: '';
    display: block;
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
    transform: rotateZ(180deg);
  }
`;

export const ImageSlide = styled.div`
  height: 40px;
  flex-shrink: 0;
  animation: ${slideShow} 20s linear infinite;
  a {
    margin: 0 35px;
    transition: all 0.3s ease;
    opacity: 0.6;
    @media only screen and (max-width: 991px) {
      margin: 0 20px;
    }
    @media only screen and (max-width: 624px) {
      margin: 0 5px;
    }
    img {
      @media only screen and (max-width: 1360px) {
        max-width: 90%;
      }
      @media only screen and (max-width: 991px) {
        max-width: 80%;
      }
    }

    &:hover {
      opacity: 1;
    }
  }
`;

export default FooterWrapper;
