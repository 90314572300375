import { graphql, useStaticQuery } from 'gatsby';

import { BiMusic } from "react-icons/all"
import Box from '../../home/components/Box';
import Container from '../../home/components/UI/Container';
import Heading from '../../home/components/Heading';
import { Icon } from 'react-icons-kit';
import React from 'react';
import SectionWrapper from './service.style';
import Text from '../../home/components/Text';
import {bitcoin} from 'react-icons-kit/fa/bitcoin'
import {briefcase} from 'react-icons-kit/fa/briefcase'
import {cloud} from 'react-icons-kit/fa/cloud'
import {database} from 'react-icons-kit/fa/database'
import {magic} from 'react-icons-kit/fa/magic'
import {music} from 'react-icons-kit/fa/music'
import {newspaperO} from 'react-icons-kit/fa/newspaperO'
import {photo} from 'react-icons-kit/fa/photo'

interface Props {
  secTitleWrapper?: any;
  secText?: any;
  secHeading?: any;
  Row: any;
  Col: any;
  serviceTitleStyle: any;
  secDes: any;
};

const BulletServices = [
  {
    "icon":  photo, 
    "title": "Frontend & Mobile"
  },
  {
    "icon":  database, //"flaticon-prototype",
    "title": "Databases & Backend"
  },
  {
    "icon":  cloud, // "flaticon-code",
    "title": "Cloud & DevOps"
  },
  {
    "icon": magic, // "flaticon-vectors",
    "title": "AI & Automation"
  },
  {
    "icon":  briefcase, //"flaticon-briefing",
    "title": "NFTs & Business"
  },
  {
    "icon":  bitcoin, // "flaticon-wallet",
    "title": "Blockchain'ers"
  },
  {
    "icon":  newspaperO, //"flaticon-creative",
    "title": "Marketing Geniuses"
  },
  {
    "icon":  music, // "flaticon-music-alt",
    "title": "Highly Musical"
  }
]

const ServiceSection = (props: Props) => {

  const {
    secTitleWrapper = {
      mb: ['60px', '80px'],
    },
    secText = {
      as: 'span',
      display: 'block',
      textAlign: 'center',
      fontSize: '14px',
      letterSpacing: '0.15em',
      fontWeight: '700',
      color: '#ff4362',
      mb: '12px',
    },
    secHeading = {
      textAlign: 'center',
      fontSize: ['20px', '24px', '36px', '36px'],
      fontWeight: '700',
      color: '#0f2137',
      letterSpacing: '-0.025em',
      mb: '0',
      ml: 'auto',
      mr: 'auto',
      lineHeight: '1.12',
      width: '540px',
      maxWidth: '100%',
    },
    Row = {
      flexBox: true,
      flexWrap: 'wrap',
      justifyContent: 'center',
      ml: ['0', '-15px', '-20px', '-35px', '-35px'],
      mr: ['0', '-15px', '-20px', '-35px', '-35px'],
    },
    Col = {
      pl: ['15px', '15px', '25x', '35px', '35px'],
      pr: ['15px', '15px', '25px', '35px', '35px'],
      mb: '40px',
    },
    serviceTitleStyle = {
      fontSize: ['15px', '15px', '15px', '16px', '17px'],
      fontWeight: '500',
      // color: '#ff4361',
      mb: 0,
    },
    secDes = {
      fontSize: ['14px', '15px', '16px'],
      color: '#5d646d',
      lineHeight: '1.875',
      mt: '30px',
      ml: 'auto',
      mr: 'auto',
      width: '470px',
      maxWidth: '100%',
      textAlign: 'center',
    }
  } = props;

  const Data = useStaticQuery(graphql`
    query {
      interiorJson {
        SERVICE_ITEMS {
          title
          icon
        }
      }
    }
  `);

  return (
    <SectionWrapper id="home-services">
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} content="SUPER SICK FAM" />
          <Heading
            {...secHeading}
            content="Covering all tech stacks"
          />
          <Text
            {...secDes}
            content="With the rise of AI & Automation, we cover all tech stacks to handle the ever changing rapid enterprise demands (from customer requirements of course)."
          />
        </Box>

        <Box {...Row}>
          {BulletServices.map((item, index) => (
            <Box
              {...Col}
              key={`service-item-${index}`}
              className="service_item_col"
            >
              <Box className="service_item">
                <Box className="service_icon">
                  <Icon icon={item.icon} size={43} />
                </Box>
                <Heading as="h3" content={item.title} {...serviceTitleStyle} />
              </Box>
            </Box>
          ))}
        </Box>
      </Container>
    </SectionWrapper>
  );
};


export default ServiceSection;
