import FooterWrapper, {
  CurvIcon,
  List,
  ListItem,
  SocialList,
} from './footer.style';
import React, { Fragment } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Box from '../../home/components/Box';
import Companies from './companies';
import Container from '../../home/components/UI/Container';
import Heading from '../../home/components/Heading';
import { Icon } from 'react-icons-kit';
import Logo from '../../home/components/UIElements/Logo';
import Text from '../../home/components/Text';
import { dribbble } from 'react-icons-kit/fa/dribbble';
import { facebook } from 'react-icons-kit/fa/facebook';
import { googlePlus } from 'react-icons-kit/fa/googlePlus';
import { skype } from 'react-icons-kit/fa/skype';
import { twitter } from 'react-icons-kit/fa/twitter';

const socialLinks = [
  {
    id: 1,
    icon: <Icon icon={facebook} />,
    name: 'facebook',
    link: '#',
  },
  {
    id: 2,
    icon: <Icon icon={dribbble} />,
    name: 'dribbble',
    link: '#',
  },
  {
    id: 3,
    icon: <Icon icon={googlePlus} />,
    name: 'googlePlus',
    link: '#',
  },
  {
    id: 4,
    icon: <Icon icon={skype} />,
    name: 'skype',
    link: '#',
  },
  {
    id: 5,
    icon: <Icon icon={twitter} />,
    name: 'twitter',
    link: '#',
  },
];


// Footer style props
interface Props {
  row?: any;
  col?: any;
  colOne?: any;
  colTwo?: any;
};

const Footer = (props: Props) => {

  // Footer row default style
  const {
    row = {
      flexBox: true,
      flexWrap: 'wrap',
      ml: '-15px',
      mr: '-15px',
    },
    // Footer col one style
    colOne = {
      width: ['100%', '30%', '35%', '28%'],
      mt: [0, '13px', '0'],
      mb: ['30px', 0],
      pl: ['15px', 0],
      pr: ['15px', '15px', 0],
    },
    // Footer col two style
    colTwo = {
      width: ['100%', '70%', '65%', '72%'],
      flexBox: true,
      flexWrap: 'wrap',
    },
    // Footer col default style
    col = {
      width: ['100%', '50%', '50%', '25%'],
      pl: '15px',
      pr: '15px',
      mb: '30px',
    },
  } = props;

  const data = useStaticQuery(graphql`
    query {
      interiorJson {
        footerData {
          logo {
            publicURL
          }
          mail
          phone
          menuWidgets {
            id
            title
            menu {
              id
              text
              link
            }
          }
        }
      }
    }
  `);
  const { logo, mail, phone, menuWidgets } = data.interiorJson.footerData;

  return (
    <Fragment>
      <CurvIcon>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 222.29">
          <g>
            <g>
              <path d="M0,222.29S547.82,11.93,1248.06.64C1867.75-9.36,1920,101.3,1920,101.3v121Z" />
            </g>
          </g>
        </svg>
      </CurvIcon>

      <FooterWrapper>
        <Container width="1330px">

          {/* Companies promo */}
          <Companies />

          {/* End of widgets row */}
          <Box className="row copyright" {...row}>
            <Text className="text" content={`Algo Inc ${new Date().getFullYear()}`} />
            <p>
              <p style={{ color: "gold", display: "flex" }}>In God We Trust ⚡️ ceddy.org</p>
            </p>
          </Box>
          {/* End of copyright row */}
        </Container>
      </FooterWrapper>
    </Fragment>
  );
};

export default Footer;
